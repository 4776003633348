import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import WhatsappChat from "../global/botonWhatsapp/BotonWhatsapp"
import { FaCheck, FaCreditCard, FaMoneyBillWave } from 'react-icons/fa'
import { FiPhoneCall, FiMapPin, FiClock } from 'react-icons/fi'
import {BiChevronRightCircle} from 'react-icons/bi'
import { GoCalendar } from 'react-icons/go'

const menu = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]

function Footer() {
  const { rpdata } = useContext(GlobalDataContext);
  const yearsActual = new Date();

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
    <div className="flex w-full">
      <div className="flex w-[80%]  mx-auto justify-center text-center">
        <div className="w-[100%] md:w-[25%] mx-auto justify-center text-center bg-white rounded-3xl -mb-32 -mt-20">
              <div className="self-center pt-4 pb-4">
                <img src={rpdata?.dbPrincipal?.logo} className="w-[100%] rounded-lg" alt="Not Found" />
              </div>
        </div>
        <div className=" md:w-[75%]">

        </div>
      </div>
    </div>
      <div className="w-full flex justify-center bg-gradient-to-t from-black to-[#2A344F] bg-opacity-5 pt-28 pb-14 md:pl-28 pl-6 text-white">
        <div className="md:max-w-screen-xl w-full">
          <div className="block md:grid md:grid-cols-4 md:gap-6 lg:gap-8 lg:grid-flow-col">
            <div className="pt-10">
              <h5>About</h5>
              <p className="pb-4">{rpdata?.dbAbout?.[1].text.substring(0, 182)}</p>
            </div>
            <div className="pt-10">
              <h5>Services</h5>
                  <ul className="list-inner-section text-start">
                  {rpdata?.dbServices?.slice(0, 7).map((item, index) => {
                    return (
                      <Link to="/services" key={index}>
                      <li key={index} className="py-1 px-2 flex items-center">
                        <BiChevronRightCircle className="h-[20px] w-[20px] text-center" />
                        <span className="px-2 text-center">{item.name}</span>
                      </li>
                      </Link>
                    )
                    })}
                  </ul>
            </div>
            <div className="pt-10">
              <h5>Navigation</h5>
              <ul className="pl-2">
                {
                  menu.map((item, index) => {
                    return (
                      <li className="py-2" onClick={goToTop} key={index}>
                        <Link to={item.link} className="flex">
                          <FaCheck className="self-center" />
                          <span className="pl-2">{item.name}</span>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="pt-10">
              <h5>Contact</h5>
              <ul className="pl-2">
                {rpdata?.dbPrincipal?.location.slice(0, 1).map((item, index) => {
                  return (
                    <Link to="/contact" key={index}>
                      <li className="py-2 flex items-center">
                        <FiMapPin fontSize={25} />
                        <span className="pl-3">{item.address}</span>
                      </li>
                    </Link>
                  );
                })}
                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <a href={`tel:+1${item.phone}`} key={index}>
                      <li className="py-2 flex items-center">
                        <FiPhoneCall fontSize={25} />
                        <span className="pl-3">{item.phone}</span>
                      </li>
                    </a>
                  );
                })}
                {rpdata?.dbPrincipal?.workdays.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index} >
                      <GoCalendar fontSize={25} />
                      <span className="pl-3">{item.day}</span>
                    </li>
                  );
                })}
                {rpdata?.dbPrincipal?.workHours.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index}>
                      <FiClock fontSize={25} />
                      <span className="pl-3">{item.hour}</span>
                    </li>
                  );
                })}
                {
                  rpdata?.dbPrincipal?.paymentMethod === 'we accept cash & checks' ?
                    <li className="py-2 flex items-center">
                      <FaMoneyBillWave fontSize={25} />
                      <span className="pl-3 capitalize">{rpdata?.dbPrincipal?.paymentMethod}</span>
                    </li>
                    :
                    <li className="py-2 flex flex-col">
                      <span className="flex items-center">
                        <FaCreditCard fontSize={25} />
                        <span className="pl-3 capitalize">{rpdata?.dbPrincipal?.paymentMethod}</span>
                      </span>
                    </li>
                }

              </ul>
            </div>
          </div>
        </div>
      </div>
      <WhatsappChat />
      <div className="w-full flex justify-around md:flex-row items-center flex-col-reverse text-center text-white  py-4 fondo">
        <p>
        ©{yearsActual.getFullYear()}, Copyright By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>
        <ul className="flex">
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <li key={index} className='mr-7'>
                <a href={item.url} target="_blank" rel='noopener noreferrer' >
                  <i
                    className={`fab fa-${item.icon}`}
                    aria-hidden="true"
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Footer;
