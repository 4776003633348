import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function BlockHomeTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-4/5 mx-auto md:py-32 py-10">
        <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[55%] relative">
            <div
              className=" flex md:mt-[15%] w-[400px] md:h-[400px] h-[400px] bg-cover bg-center rounded-full right-0 absolute mt-5 border-[10px]"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[2]}")` }}
            ></div>
            <div
              className="w-[70%] md:h-[600px] h-[350px] bg-cover bg-no-repeat bg-center rounded-3xl lg:flex hidden"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[1]}")` }}
            ></div>

          </div>

          <div className="md:w-[45%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[4].slogan}</h2>
            <p className="pb-4">{rpdata?.dbHome?.[1].text}</p>
            <ButtonContent />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}


export default BlockHomeTwo;
